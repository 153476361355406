import React from 'react';
import { SmileOutlined } from '@ant-design/icons';
import { Result } from 'antd';

const StockEnd: React.FC = () => (
  <Result
    icon={<SmileOutlined />}
//    title="Great, we have done all the operations!"
    title="Thank you, for submitting your order!"
  />
);

export default StockEnd;
