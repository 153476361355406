import React, { useEffect, useState } from 'react';
import { Table, InputNumber, Button, notification, Spin } from 'antd';
import { storage, db } from './firebase';
import { ref, uploadString } from 'firebase/storage';
import { collection, getDocs } from 'firebase/firestore';
import StockEnd from './StockEnd';
import { SortOrder } from 'antd/lib/table/interface';

// Define interface for the props expected by the StockTable component
interface StockTableProps {
  MetaData: {
    option: string;
    name: string;
    checkbox: boolean;
    guests: number;
    error: number;
  };
}

// Define interface for the data structure used in the StockTable component
interface MyData {
  id: string;
  section?: string;
  editableValue?: number;
  stock_size?: string;
  name?: string;
  layoutOrder: number;
}

const StockTable: React.FC<StockTableProps> = ({ MetaData }) => {
  const [data, setData] = useState<MyData[]>([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [editedFields, setEditedFields] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setIsEnd(false);
      try {
        const collectionRef = collection(db, `${MetaData.option}`);
        const Snapshot = await getDocs(collectionRef);
        const dataList = Snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })) as MyData[];

        if (MetaData.checkbox) {
          const filteredData = dataList.filter(record => record.section === 'Fresh');
          setData(filteredData);
        } else {
          setData(dataList);
        }
      } catch (error) {
        notification.error({ message: 'Error fetching data', description: (error as Error).message });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [MetaData]);

  const handleInputChange = (value: number, recordId: string) => {
    setData(prevData =>
      prevData.map(item => {
        if (item.id === recordId) {
          setEditedFields(prevState => ({ ...prevState, [recordId]: true }));
          return { ...item, editableValue: value };
        }
        return item;
      })
    );
  };



  const generateJSONData = (): string => {
    const modifiedData = data.reduce((acc: MyData[], item) => {
      if (editedFields[item.id]) {
        return [...acc, { ...item, editableValue: item.editableValue ?? 0 }];
      }
      return acc;
    }, []);

    const specificEntry = {
      name: MetaData.name,
        camp: MetaData.option,
      guestNumber: MetaData.guests,
      errorEntry: MetaData.error,
    };

    const finalData = [specificEntry, ...modifiedData];
    return JSON.stringify(finalData);
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      const jsonData = generateJSONData();

      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const filename = `${year}${month}${day} - ${MetaData.option} - ${hours}:${minutes}.json`;

      const storageRef = ref(storage, `stock/${filename}`);
      await uploadString(storageRef, jsonData, 'raw');
      notification.success({ message: 'Order submitted successfully' });
      setIsEnd(true);
    } catch (error) {
      notification.error({ message: 'Error submitting order!', description: (error as Error).message });
    } finally {
      setSubmitting(false);
    }
  };

  const groupDataByStoreroom = (data: MyData[]) => {
    const groupedData: { [key: string]: MyData[] } = {};
    data.forEach(item => {
      const storeroom = item.section || 'Unknown';
      if (!groupedData[storeroom]) {
        groupedData[storeroom] = [];
      }
      groupedData[storeroom].push(item);
    });
    return groupedData;
  };

  const columns = [
    {
      title: 'Stock',
      dataIndex: 'editableValue',
      key: 'editableValue',
      width: 122,
      render: (text: any, record: MyData) => (
        <InputNumber
          value={record.editableValue}
          onChange={value => handleInputChange(value as number, record.id)}
          type="number"
        />
      ),
    },
    {
      title: 'Size',
      dataIndex: 'stock_size',
      key: 'stock_size',
      width: 122,
    },
    {
      title: '',
      dataIndex: 'layoutOrder',
      key: 'layoutOrder',
      sorter: (a: MyData, b: MyData) => (a.layoutOrder ?? 0) - (b.layoutOrder ?? 0),
      defaultSortOrder: 'ascend' as SortOrder,
      hidden: true,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
  ];

  const groupedData = groupDataByStoreroom(data);

  return (
    <div>
      {loading ? (
        <Spin size="large" style={{ justifyContent: 'center', padding: '20px', display: 'flex' }} />
      ) : isEnd ? (
        <StockEnd />
      ) : (
        <>
          {Object.keys(groupedData).map(storeroom => (
            <div key={storeroom}>
              <h3>{storeroom}</h3>
              <Table
                dataSource={groupedData[storeroom]}
                columns={columns}
                rowKey="id"
                pagination={false}
                loading={loading}
                size="small"
              />
            </div>
          ))}
          <Button type="primary" onClick={handleSubmit} disabled={submitting} style={{ marginTop: '16px' }}>
            Submit order for review
          </Button>
        </>
      )}
    </div>
  );
};

export default StockTable;
