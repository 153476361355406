import React, { useState } from 'react';
import { Layout, Input, Button, notification } from 'antd';
import Header from './Header';
import { User } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore'; // Import Firestore methods
import { db } from './firebase'; // Ensure correct import
import './App.css';
import StockCompliance from './StockCompliance';

const { Content } = Layout;

const App: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [accessCode, setAccessCode] = useState<string>('');
  const [isCodeCorrect, setIsCodeCorrect] = useState<boolean>(false);

  const handleAccessCodeSubmit = async () => {
    try {
      const docRef = doc(db, 'system', 'access_code'); // Use 'doc' to get the document reference
      const docSnap = await getDoc(docRef); // Use 'getDoc' to fetch the document

      if (docSnap.exists()) {
        const systemData = docSnap.data();
        const correctAccessCode = systemData?.code; // Ensure the field name matches

        if (accessCode === correctAccessCode) {
          setIsCodeCorrect(true);
        } else {
          notification.error({ message: 'Incorrect access code. Please try again.' });
          setAccessCode('');
        }
      } else {
        notification.error({ message: 'System document not found.' });
      }
    } catch (error) {
      console.error('Error fetching system document:', error);
      notification.error({ message: 'Error fetching system document. Please try again later.' });
    }
  };

  return (
    <Layout className="layout">
      <Header />
      <Content className="content" style={{ padding: '0 50px' }}>
        <div className="site-layout-content">
          {isCodeCorrect ? (
            <StockCompliance />
          ) : (
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
              <h2>Enter Access Code</h2>
              <Input
                type="text"
                value={accessCode}
                onChange={(e) => setAccessCode(e.target.value)}
                placeholder="Access Code"
                style={{ width: '200px', marginRight: '10px' }}
              />
              <Button type="primary" onClick={handleAccessCodeSubmit} disabled={!accessCode}>
                Submit
              </Button>
            </div>
          )}
        </div>
      </Content>

    </Layout>
  );
};

export default App;
