import React from 'react';
import { Layout, Row, Col, Avatar } from 'antd';
import './App.css';

const url = require('./logo.png');
const { Header: AntHeader } = Layout;

const Header: React.FC = () => {
  return (
    <AntHeader className="header">
      <div className="logo" />
      <Row justify="center" align="middle">
        <Col>
          <Avatar shape="square" size={64} src={url} />
        </Col>
        <Col>
          <h1 style={{ display: 'inline', marginLeft: 16 }}>Order System</h1>
        </Col>
      </Row>
    </AntHeader>
  );
};

export default Header;
