import React from 'react';
import { Divider, Table } from 'antd';
import type { TableColumnsType } from 'antd';
import StockTable from './StockTable';
import { Button, Space } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';


interface HeaderProps {
    data: {
        option: string;
        name: string;
        checkbox: boolean;
    guests: number;
    error: number;
    } ;
}

const StockComponent: React.FC<HeaderProps> = ({ data }) => {

    const handlePrint = () => {
      window.print();
    };
    
  return (
        <>
            <Divider>
          
          <Space>
          {data.option.toUpperCase()}
          <Button type="primary" shape="circle"  icon={<PrinterOutlined />} onClick={handlePrint} />
        </Space>
              
         </Divider>

               <StockTable MetaData={ data } />

          
        </>
  );
};

export default StockComponent;
