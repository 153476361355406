import React, { useState } from 'react';
import { Form, Input, Button, Select, Checkbox, InputNumber } from 'antd';
import StockComponent from './StockComponent'; // Importing the StockComponent

const { Option } = Select; // Destructuring Select component from antd library

interface FormData {
  option: string;
  name: string;
  checkbox: boolean; // Boolean to track checkbox state
  guests: number; // Number of guests
  error: number; // Error number
}

const StockCompliance = () => {
  // State variables to track form submission and form data
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    option: '', // Selected option
    guests: 0, // Default number of guests
    error: 3, // Default error number
    name: '', // User's name
    checkbox: false, // Default checkbox state
  });

  // Function to handle form submission
  const handleFinish = (values: FormData) => {
    setFormData(values); // Set form data
    setSubmitted(true); // Set form submission status to true
  };

  return (
    <>
      {!submitted ? ( // If form is not submitted, render the form
        <Form
          name="test-form"
          onFinish={handleFinish} // Callback function when form is submitted
          initialValues={{ error: 3, guests_specify: 0 }} // Initial values for form fields
          style={{ maxWidth: 300, margin: 'auto', marginTop: 50 }} // Style for the form
        >
          {/* Select option dropdown */}
          <Form.Item
            label="Select Option"
            name="option"
            rules={[{ required: true, message: 'Please select an option!' }]}
          >
            <Select>
              <Option value="bushcamp">Bushcamp</Option>
              <Option value="guestfarm">Guestfarm</Option>
            </Select>
          </Form.Item>

          {/* Input field for user's name */}
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please input your name!' }]}
          >
            <Input />
          </Form.Item>

          {/* Dropdown menu for specifying guests */}
          <Form.Item
            label="Specify Guests"
            name="guests_specify"
                    
            rules={[
              {
                required: true,
                message: 'Please specify if guests are needed!',
              },
            ]}
          >
            <Select  disabled >
              <Option value={0}>No</Option>
              <Option value={1}>Yes</Option>
            </Select>
          </Form.Item>

          {/* Conditional rendering of guests and error fields based on dropdown selection */}
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.guests_specify !== currentValues.guests_specify
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('guests_specify') === 1 && (
                <>
                  {/* Input field for number of guests */}
                  <Form.Item
                    name="guests"
                    label="Guests"
                    rules={[
                      { required: true, message: 'Please specify number of guests!' },
                    ]}
                  >
                    <InputNumber min={0} />
                  </Form.Item>

                  {/* Input field for error number */}
                  <Form.Item
                    name="error"
                    label="Error"
                    rules={[{ required: true, message: 'Please specify error number!' }]}
                  >
                    <InputNumber min={0} />
                  </Form.Item>
                </>
              )
            }
          </Form.Item>

          {/* Checkbox for ordering only fresh produce */}
          <Form.Item name="checkbox" valuePropName="checked">
            <Checkbox>Order only fresh produce!</Checkbox>
          </Form.Item>

          {/* Submit button */}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      ) : (
        // If form is submitted, render the StockComponent with form data
        <StockComponent data={formData} />
      )}
    </>
  );
};

export default StockCompliance;
