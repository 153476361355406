// src/firebase.ts
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
apiKey: "AIzaSyB9RzSX1gNy3iSACAhlJfJHFY9_HoTpipU",
authDomain: "react-firebase-app-29530.firebaseapp.com",
projectId: "react-firebase-app-29530",
storageBucket: "react-firebase-app-29530.appspot.com",
messagingSenderId: "1071518360053",
appId: "1:1071518360053:web:146a870fc349d43c0f40bc"
};


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };

